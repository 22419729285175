<template>
  <footer>
    <div class="footer-wrapper">
      <div class="logo">
        <img :src="require('@/assets/img/neuco-white-logo.svg')" alt="logo">
      </div>
      <div class="nav">
        <NavLinks />
      </div>
      <p class="copyright-text">{{`Copyright© ${(new Date()).getFullYear()} Neuco Neural Corporation. Todos os direitos reservados.`}}</p>
    </div>
  </footer>
</template>

<script>
import NavLinks from "@/components/NavLinks.vue";

export default {
  name: 'Footer',
  components: {
    NavLinks,
  }
}
</script>

<style scoped>
footer {
  width: 100%;
  min-height: 180px;
  padding: 1.8rem 0rem 6rem 0rem;
  background-color: var(--primary-color);
  background-image: linear-gradient(90deg, var(--primary-color) 0%, var(--primary-color-400) 100%);
  display: flex;
  align-items: center;
}

.footer-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 0px 15px;
  display: grid;
  justify-items: center;
  row-gap: 15px;
}

.logo {
  display: flex;
  height: 45px;
}

.logo img {
  display: block;
  height: 100%;
}

.copyright-text {
  font-size: 1rem;
  font-weight: 100;
  margin: 0;
  padding: 0;
  color: white;
  text-align: center;
}

/*
  NAV
*/

.nav >>> ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: white;
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}

.nav >>> ul li {
  cursor: pointer;
  padding: 0.25rem 0rem;
  margin: 0.125rem 0rem;
  user-select: none;
}

.nav >>> ul li:active {
  color: var(--secondary-color);
}

/* md para cima */
@media (min-width: 768px) {
  footer {
    padding: 1.8rem 0rem;
  }

  .nav >>> ul {
    font-size: 1rem;
  }

  .nav >>> ul li {
    display: inline;
  }

  .nav >>> ul li:not(:first-child) {
    padding: 0 0 0 0.5rem;
  }

  .nav >>> ul li:not(:last-child)::after {
    content: "•";
    display: inline;
    padding: 0 0 0 0.5rem;
    color: white;
  }

  .nav >>> ul li:hover {
    color: var(--secondary-color);
  }
}
</style>
