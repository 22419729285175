<template>
  <section class="hero pt-header-size section-fill-screen-min-height-without-header" id="15dfb042-home" >
    <div class="hero-container">
      <!-- HERO SHAPE -->
      <div class="hero-image-container">
        <HeroShape />
      </div>

      <div class="hero-text">
        <h1>Nós construímos software para o seu negócio.</h1>
        <p>Inteligência Artificial aplicada em seu negócio.</p>
        <ul>
          <li>Combate a Fraudes</li>
          <li>Estabelecimento de Padrões</li>
          <li>Modelos preditivos</li>
          <li>Otimização de Processos</li>
        </ul>
        <div class="button-container">
          <button class="btn btn--secondary-outline btn--hover-shadow btn--block btn-md--default" @click="goToAboutUsSection()">Saber mais</button>
          <button class="btn bg-secondary text-white btn--hover-shadow btn--block btn-md--default" @click="goToHireUsSection()">Contrate-nos</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeroShape from "./HeroShape.vue";
import util from "@/services/util";

export default {
  name: 'HeroSection',

  components: {
    HeroShape,
  },

  methods: {
    goToHireUsSection() {
      util.scrollBehaviorToId('15dfb042-hire-us-section')
    },
    goToAboutUsSection() {
      util.scrollBehaviorToId('15dfb042-about-us-section');
    },
  }
}
</script>

<style scoped>
.hero {
  background-color: var(--primary-color);
  background-image: linear-gradient(90deg, var(--primary-color) 0%, var(--primary-color-400) 100%);
  display: flex;
  justify-content: center;
}

.hero-container {
  margin: auto;
  padding: 0px 15px;
  max-width: 1200px;
  display: grid;
  grid-template-rows: minmax(0, 50vh) minmax(0, 1fr);
  align-items: center;
}

.hero-image-container {
  height: 100%;
}

.hero-text {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 1rem 0rem;
}

.hero-text h1 {
  font-size: 2.125rem;
  color: var(--secondary-color);
  text-transform: uppercase;
}

.hero-text p {
  font-size: 1.25rem;
  color: white;
}

.hero-text ul {
  list-style: none;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 1rem;
  cursor: default;
}

.hero-text ul li::before {
  content: "\2022\00a0"; /* &bull;&nbsp; */
}

.hero-text .button-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

/* md para cima */
@media (min-width: 768px) {
  .hero-container {
    grid-template-rows: none;
    grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
    column-gap: 1.25rem;
  }

  .hero-text .button-container {
    flex-direction: row;
    row-gap: 0px;
    column-gap: 15px;
  }

  .hero-text ul li:hover {
    color: var(--secondary-color);
  }
}
</style>
