<template>
  <ul>
    <li @click.stop="scrollBehaviorToId('15dfb042-home')">Home</li>
    <li @click.stop="scrollBehaviorToId('15dfb042-about-us-section')">Sobre</li>
    <li @click.stop="scrollBehaviorToId('15dfb042-solution-section')">Soluções</li>
    <li @click.stop="scrollBehaviorToId('15dfb042-our-steps-section')">Nossos Passos</li>
    <li @click.stop="scrollBehaviorToId('15dfb042-hire-us-section')">Contrate-nos</li>
  </ul>
</template>

<script>
import util from "@/services/util";

export default {
  name: 'NavLinks',

  methods: {
    emitLinkClick(id) {
      this.$emit('link-click', id);
    },

    scrollBehaviorToId(id) {
      this.emitLinkClick(id);
      util.scrollBehaviorToId(id);
    },
  }
}
</script>
