<template>
  <div class="video-preview" :class="{ 'video-preview--light': !dark, 'video-preview--dark': dark }">
    <div class="play-icon" :class="{ 'play-icon--light': !dark, 'play-icon--dark': dark }">
      <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M10,16.5L16,12L10,7.5V16.5Z" />
      </svg>
    </div>
    <img :src="videoThumbnail" :alt="videoThumbnailAlt">
  </div>
</template>

<script>
export default {
  name: 'VideoPreview',
  props: {
    videoThumbnail: String,
    videoThumbnailAlt: String,
    dark: Boolean,
  },
}
</script>

<style scoped>
.video-preview {
  background: white;
  width: 100%;
  max-width: 640px;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 200ms ease;
}

.video-preview::before {
  /* Aspect Ratio Boxe 16:9 */
  /* https://ratiobuddy.com/ */
  /* https://css-tricks.com/aspect-ratio-boxes/ */
  content: "";
  display: block;
  width: 0px;
  padding-top: calc((9 / 16) * 100%);
}

.video-preview::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
}

.video-preview--light::after {
  background: rgba(255, 255, 255, 0.69);
  border-radius: 8px;
}

.video-preview--dark::after {
  background: rgba(var(--primary-color-rgb), 0.69);
}

.video-preview img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}

.play-icon {
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-icon--light {
  color: var(--tertiary-color);
}

.play-icon--dark {
  color: white;
}

.play-icon svg {
  height: 100%;
  width: 100%;
  transition: transform 300ms ease;
}

.video-preview:hover {
  transform: translateY(-7px);
}

.video-preview:hover .play-icon svg {
  transform: scale(1.1);
}
</style>
