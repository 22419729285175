import Vue from 'vue'
import App from './App.vue'
import router from './router'
// Smooth Scroll behavior polyfill (enable smooth scrolling on mobile iOs)
import smoothscroll from 'smoothscroll-polyfill';
import '@/styles/global.css'

Vue.config.productionTip = false

// starts the polyfill
smoothscroll.polyfill();
// https://stackoverflow.com/questions/8330559/hover-effects-using-css3-touch-events
document.body.addEventListener('touchstart',function(){},false);
// document.addEventListener("touchstart", function(){}, true)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
