<template>
  <!-- ABOUT -->
  <section class="pt-header-size section-default-min-height-with-header">
    <div class="about-container">
      <div class="about-text">
        <hr class="short-hr-line short-hr-line--center bg-primary">
        <h2 class="title-h2 text-secondary">Sobre a NEUCO</h2>
        <h1 class="title-h1 text-primary">Quem somos nós</h1>
        <p class="title-description">A Neuco é uma empresa especializada em inteligência artificial que acredita no trabalho em conjunto entre pessoas e robôs. <span>Assista o vídeo abaixo e entenda nossa visão e missão</span>.</p>
      </div>
      <VideoPreview
        dark
        :videoThumbnail="require('@/assets/img/neuco-black-logo.svg')"
        videoThumbnailAlt="logo NEUCO"
        @click.native="showVideo()"
      />
    </div>
    <YoutubeVideoOverlay
      v-model="showVideoOverlay"
      :showPlayerControls="true"
      youtubeVideoId="1ZcZCeYXQsw"
    />
  </section>
</template>

<script>
import VideoPreview from "@/components/VideoPreview.vue";
import YoutubeVideoOverlay from "@/components/YoutubeVideoOverlay.vue";

export default {
  name: 'AboutUsSection',

  components: {
    VideoPreview,
    YoutubeVideoOverlay,
  },

  data: () => ({
    showVideoOverlay: false,
  }),

  methods: {
    showVideo() {
      this.showVideoOverlay = true;
    },
  }
}
</script>

<style scoped>
section {
  padding-bottom: 6.25rem;
  background: white;
}

.about-container {
  max-width: 1280px;
  margin: auto;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* TEXT */
.about-text {
  text-align: center;
}

.about-text h2 {
  margin-top: 15px;
}

.about-text p {
  margin: 30px 0px;
}

/* md para cima */
@media (min-width: 768px) {
  .about-text p {
    padding: 0px 120px;
  }
}
</style>
