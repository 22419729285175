<template>
  <nav class="nav-wrapper">
    <input type="checkbox" class="toggler" v-model="menuIsVisible">
    <span class="hamburger">
      <span></span>
    </span>
    <div class="menu" @click="closeMenu()">
      <NavLinks @link-click="closeMenu()" />
    </div>
  </nav>
</template>

<script>
import NavLinks from "@/components/NavLinks.vue";

export default {
  name: 'Nav',

  components: {
    NavLinks,
  },

  data: () => ({
    menuIsVisible: false,
  }),

  watch: {
    menuIsVisible(isVisible) {
      if (isVisible) {
        document.documentElement.classList.add("noscroll");
      } else {
        document.documentElement.classList.remove("noscroll");
      }
    },
  },

  methods: {
    closeMenu() {
      this.menuIsVisible = false;
    },
  }
}
</script>

<style scoped>
.nav-wrapper {
  position: relative;
}

/*
  Checkbox invisivel
*/

.nav-wrapper > .toggler,
.nav-wrapper > .hamburger {
  height: 3rem;
  width: 3rem;
  user-select: none;
}

.nav-wrapper > .toggler {
  position: absolute;
  z-index: 99;
  opacity: 0;
  cursor: pointer;
}

/*
  Menu
*/

.menu {
  /* Comente essa linha para debug */
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.menu::before {
  content: "";
  overflow: hidden;
  position: absolute;
  z-index: -1;
  display: block;
  height: 200%;
  width: 200%;
  border-radius: 50%;
  background: rgba(var(--primary-color-rgb), 0.5);
  background-image: linear-gradient(to bottom, rgba(var(--primary-color-rgb), 0.5) 0%, rgba(var(--primary-color-rgb), 0.95) 50%);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s ease-out;
}

.menu >>> ul {
  /* Comente essa linha para debug */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  color: white;
  font-size: 1.75rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  user-select: none;
  opacity: 0;
  transition: all 0.5s ease-out;
}

.menu >>> ul li {
  text-align: center;
  width: 100%;
  cursor: pointer;
  padding: 0.25rem 0rem;
  margin: 0.25rem 0rem;
  user-select: none;
}

.menu >>> ul li:active {
  color: var(--secondary-color);
}

/*
  Botão Hamburger
*/

.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0.5rem;
}

/*
  Barras
*/

.hamburger > span {
  position: relative;
}

.hamburger > span,
.hamburger > span::before,
.hamburger > span::after {
  display: block;
  width: 100%;
  height: 0.15rem;
  background: white;
  border-radius: 5px;
  transition: all 0.4s ease;
}

.hamburger > span::before,
.hamburger > span::after {
  content: "";
  position: absolute;
  left: 0;
}

/* 1a barra */
.hamburger > span::before {
  top: -0.6rem;
}

/* 3a barra */
.hamburger > span::after {
  top: 0.6rem;
}

/*
  Botao Hamburger Ativo
*/

.nav-wrapper > .toggler:checked + .hamburger {
  border: thin solid rgba(255, 255, 255, 0.8);
}

.nav-wrapper > .toggler:checked + .hamburger > span {
  transform: rotate(1.125turn);
}

.nav-wrapper > .toggler:checked + .hamburger > span::before,
.nav-wrapper > .toggler:checked + .hamburger > span::after {
  top: 0;
  transform: rotate(90deg);
}

/*
  Menu Ativo
*/
.nav-wrapper > .toggler:checked ~ .menu {
  visibility: visible;
}

.nav-wrapper > .toggler:checked ~ .menu >>> ul {
  opacity: 1;
}

.nav-wrapper > .toggler:checked ~ .menu::before {
  opacity: 1;
  transform: scale(1);
}

/* 'md' para cima */
@media (min-width: 768px) {
  .toggler,
  .hamburger,
  .menu::before {
    display: none;
  }

  .menu {
    visibility: visible;
    position: static;
  }

  .menu >>> ul {
    opacity: 1;
    flex-direction: row;
    column-gap: 1rem;

    font-size: 1rem;
    letter-spacing: 0.05em;
  }

  .menu >>> ul li {
    border-bottom: 2px solid transparent;
    white-space: nowrap;
    transition: border-color 300ms ease;
  }

  .menu >>> ul li:hover {
    border-bottom-color: var(--secondary-color);
  }
}
</style>
