function scrollBehaviorToId(id) {
  const el = document.getElementById(id);
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' });
  } else {
    throw new Error(`Não foi encontrado um elemento com id "${id}"`);
  }
}

function scrollToTop() {
  // Usando http://iamdustan.com/smoothscroll/ para suporte mobile iOs
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}

export default {
  scrollBehaviorToId,
  scrollToTop,
};
