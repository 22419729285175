<template>
  <button
    @click="scrollToTop()"
    :class="{ 'button--scrolled': scrolledThePage }"
  ></button>
</template>

<script>
import util from "@/services/util";
import _throttle from "lodash/throttle";

export default {
  name: 'ScrollToTopButton',

  data: () => ({
    scrolledThePage: false,
    throttled: null,
  }),

  created() {
    // '_throttle' Evita atualizar excessivamente a posição durante a rolagem.
    this.throttled = _throttle(this.handleScroll, 250);
    document.addEventListener('scroll', this.throttled);
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.throttled);
  },

  methods: {
    scrollToTop() {
      util.scrollToTop();
    },

    handleScroll() {
      this.scrolledThePage = document.documentElement.scrollTop >= document.documentElement.clientHeight;
    },
  }
}
</script>

<style scoped>
button {
  position: fixed;
  z-index: 90;
  bottom: 1rem;
  right: 1rem;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  background: var(--secondary-color);
  appearance: none;
  user-select: none;
  border: none;
  border-radius: 50%;

  /* https://stackoverflow.com/questions/3331353/transitions-on-the-css-display-property */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s;
}

button::before {
  content: "";
  display: block;
  width: 30%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%) rotate(-45deg);
  border-top: 4px solid white;
  border-right: 4px solid white;
  border-left: none;
  border-bottom: none;
}

button:active {
  background: var(--primary-color);
}

.button--scrolled {
  visibility: visible;
  opacity: 1;
}

/* `md` para cima */
@media (min-width: 768px) {
  button {
    display: none;
  }
}
</style>
