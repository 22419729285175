<template>
  <transition name="containertransition">
    <div @click="emitInput(false)" class="video-container" v-if="value">
      <div @click.stop="" class="ratio-boxe">
        <button @click="emitInput(false)" class="close-button"></button>
        <div class="iframe-container">
          <iframe :src="`https://www.youtube-nocookie.com/embed/${youtubeVideoId}${showPlayerControls ? '' : '?controls=0'}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'YoutubeVideoOverlay',

  props: {
    value: Boolean,
    youtubeVideoId: { type: String, required: true },
    showPlayerControls: Boolean,
  },

  watch: {
    value(isVisible) {
      if (isVisible) {
        document.documentElement.classList.add("noscroll");
      } else {
        document.documentElement.classList.remove("noscroll");
      }
    },
  },

  methods: {
    emitInput(value) {
      this.$emit('input', value);
    },
  }
}
</script>

<style scoped>
/*
  Container global
*/

.video-container {
  position: fixed;
  z-index: 110;
  /* https://stackoverflow.com/questions/28080910/what-does-top-0-left-0-bottom-0-right-0-mean */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.925);
}

/* https://vuejs.org/v2/guide/transitions.html */
/* Vue Transition `containertransition` */
.containertransition-enter-active, .containertransition-leave-active {
  transition: opacity 0.5s;
}
.containertransition-enter, .containertransition-leave-to {
  opacity: 0;
}

/*
  Caixa que mantém proporção
*/

.ratio-boxe {
  margin: 0 1rem;
  padding: 2rem 0.5rem;
  position: relative;
  background: var(--primary-color);
  width: 100%;
  max-height: 90vh;
  max-width: 1280px;
  display: flex;
  border-radius: 0.5rem;
}

.ratio-boxe::before {
  content: "";
  display: block;
  width: 0px;
  /* Aspect Ratio Boxe 16:9 */
  padding-top: calc((9 / 16) * 100%);
}

/*
  Botão de fechar
*/

.close-button {
  appearance: none;
  background: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 0.125rem;
  right: 0.125rem;
  width: 1.75rem;
  height: 1.75rem;
  border: none;
}

.close-button::before,
.close-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  height: 5px;
  width: 100%;
  background: white;
  border-radius: 5px;
}

.close-button:active:before,
.close-button:active::after {
  background: var(--secondary-color);
}

.close-button::before {
  transform: translate(-50%, -50%) rotateZ(45deg);
}

.close-button::after {
  transform: translate(-50%, -50%) rotateZ(-45deg);
}

/*
  Container do iframe
*/

.iframe-container {
  position: relative;
  flex: 1;
  border-radius: 1px;
  background: rgba(0, 0, 0, 0.8);
  box-shadow:
    rgba(var(--secondary-color-rgb), 0.3) 0px 0px 0px 3px,
    rgba(var(--secondary-color-rgb), 0.2) 0px 0px 0px 4px,
    rgba(var(--secondary-color-rgb), 0.1) 0px 0px 0px 5px;
}

/* Animacao de carregamento dentro do container do iframe */
.iframe-container::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(0.1turn);
  display: block;
  width: 50px;
  height: 50px;
  border-width: 5px;
  border-style: solid;
  border-top-color: var(--secondary-color);
  border-bottom-color: white;
  border-left-color: white;
  border-right-color: white;
  border-radius: 50%;
  animation: loading 1.5s linear infinite;
}

@keyframes loading {
  to {
    transform: translate(-50%, -50%) rotateZ(1.1turn);
  }
}

/*
  iframe responsivo
*/

.iframe-container > iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* `md` para cima */
@media (min-width: 768px) {
  .ratio-boxe {
    margin: 0;
    padding: 2rem;
    width: 75%;
  }
}
</style>
