<template>
  <section class="pt-header-size section-default-min-height-with-header">
    <div class="section-wrapper">
      <div class="solution-text">
        <hr class="short-hr-line bg-secondary">
        <h2 class="title-h2 text-white">Nossas Soluções</h2>
        <h1 class="title-h1 text-secondary">Neuco Smart Parking</h1>
        <p class="title-description text-white">Solução de Automação para Gestão e Controle de Estacionamentos.</p>
      </div>
      <VideoPreview
        :videoThumbnail="require('@/assets/img/neuco-smart-parking-preview.jpg')"
        videoThumbnailAlt="logo NEUCO"
        @click.native="showVideo()"
      />
    </div>
    <YoutubeVideoOverlay
      v-model="showVideoOverlay"
      :showPlayerControls="true"
      youtubeVideoId="wgP6S2VKfFw"
    />
  </section>
</template>

<script>
import VideoPreview from "@/components/VideoPreview.vue";
import YoutubeVideoOverlay from "@/components/YoutubeVideoOverlay.vue";

export default {
  name: 'SolutionsSection',

  components: {
    VideoPreview,
    YoutubeVideoOverlay,
  },

  data: () => ({
    showVideoOverlay: false,
  }),

  methods: {
    showVideo() {
      this.showVideoOverlay = true;
    },
  }
}
</script>

<style scoped>
section {
  padding-bottom: 6.25rem;
  background-image: linear-gradient(to bottom, rgba(var(--primary-color-rgb), 0.773), rgba(var(--primary-color-400-rgb), 0.773)), url('~@/assets/img/john-matychuk-yvfp5YHWGsc-unsplash-min.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;
}

.section-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

/* TEXT */
.solution-text h2 {
  margin-top: 15px;
}

.solution-text p {
  margin-top: 30px;
}

/* md para cima */
@media (min-width: 768px) {
  .section-wrapper {
    display: grid;
    /* Solução usando nomear explicitamente as linhas */
    /* grid-template-columns: [col1] minmax(0, 7fr) [col2] minmax(0, 5fr) [endLine];
    grid-template-rows: [row1] minmax(0, 1fr) [endLine]; */
    grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
    grid-template-rows: minmax(0, 1fr);
    align-items: center;
    row-gap: 0px;
    column-gap: 15px;
  }

  .section-wrapper .solution-text {
    /* Solução usando nomear explicitamente as linhas */
    /* grid-column: col2 / endLine;
    grid-row: row1 / endLine; */
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}
</style>
