<template>
  <header :class="{ 'header--scrolled': scrolledThePage }">
    <div class="header-container">
      <div class="logo">
        <img :src="require('@/assets/img/neuco-white-logo.svg')" alt="logo">
      </div>

      <div class="nav">
        <Nav />
      </div>
    </div>
  </header>
</template>

<script>
import Nav from "@/components/Nav.vue";
import _throttle from "lodash/throttle";

export default {
  name: 'Header',
  components: {
    Nav,
  },
  data: () => ({
    scrolledThePage: false,
    throttled: null,
  }),
  created() {
    // '_throttle' Evita atualizar excessivamente a posição durante a rolagem.
    this.throttled = _throttle(this.handleScroll, 250);
    document.addEventListener('scroll', this.throttled);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.throttled);
  },
  methods: {
    handleScroll() {
      this.scrolledThePage = document.documentElement.scrollTop >= 16;
    },
  }
}
</script>

<style scoped>
header {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: var(--header-height);
  transition:
    background-color 600ms cubic-bezier(0.5, 1, 0.89, 1),
    box-shadow 500ms cubic-bezier(0.5, 1, 0.89, 1) 100ms;
}

.header--scrolled {
  background: var(--primary-color);
  /* https://getcssscan.com/css-box-shadow-examples */
  box-shadow:
    0px 5px  rgba(var(--primary-color-rgb), 0.4),
    0px 10px rgba(var(--primary-color-rgb), 0.3),
    0px 15px rgba(var(--primary-color-rgb), 0.2),
    0px 20px rgba(var(--primary-color-rgb), 0.1),
    0px 25px rgba(var(--primary-color-rgb), 0.05);
}

.header-container {
  margin: auto;
  padding: 0px 15px;
  max-width: 1200px;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 6fr) minmax(0, 6fr);
  grid-template-rows: 100%;
}

.logo,
.nav {
  display: flex;
  align-items: center;
}

.logo img {
  display: block;
  height: 100%;
  max-height: 45px;
}

.nav {
  justify-content: flex-end;
}
</style>
