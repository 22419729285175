<template>
  <section class="pt-header-size section-default-min-height-with-header">
    <div class="section-wrapper">
      <div class="section-text">
        <hr class="short-hr-line short-hr-line--center bg-secondary">
        <h2 class="title-h2 text-white">
          <span>Fale Conosco</span>
          <span>Contato</span>
          <span>Contrate-nos</span>
        </h2>
        <h1 class="title-h1 text-secondary">Gostaríamos muito de lhe conhecer</h1>
        <p class="title-description text-white">Gostaríamos muito de lhe conhecer e possivelmente estabelecermos uma parceria! Então, entre em contato conosco que faremos tudo para lhe entregar a melhor solução!</p>
      </div>

      <!-- FIXME: Implementar validação do formulário -->
      <!-- Formulário de contato -->
      <form class="contact-form" @submit.prevent="() => {}">
        <input type="text" placeholder="Nome">
        <input type="text" placeholder="E-mail">
        <input type="text" placeholder="Assunto">
        <textarea placeholder="Mensagem"></textarea>
        <input type="submit" value="Enviar" class="btn btn--block btn--hover-tertiary bg-secondary text-white">
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HireUsSection',
}
</script>

<style scoped>
section {
  padding-bottom: 6.25rem;
  background-image: linear-gradient(to bottom, rgba(var(--primary-color-rgb), 0.773), rgba(var(--primary-color-400-rgb), 0.773)), url('~@/assets/img/bg-hire-us.jpg');
  background-size: cover;
}

.section-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 0px 15px;
}

/* TEXT */
.section-text {
  text-align: center;
}

.section-text h2 {
  margin-top: 15px;
}

.section-text h2 span {
  display: block;
}

.section-text h2 span:not(:last-child)::after {
  content: "\00a0/"; /* &nbsp;/; */
}

.section-text p {
  margin: 30px 0px;
  padding: 0;
}

/* FORM */
.contact-form {
  display: grid;
  grid-template-rows: repeat(3, min-content) max-content;
  row-gap: 15px;
  background: rgba(255, 255, 255, 0.5);
  padding: 15px;
  border-radius: 5px;
}

.contact-form textarea {
  resize: vertical;
  min-height: 270px;
}

.contact-form textarea,
.contact-form input[type="text"] {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  padding: 1.25rem 1rem;
  font-size: 1rem;
}

.contact-form textarea::placeholder,
.contact-form input[type="text"]::placeholder {
  color: #a0a0a0;
  opacity: 1;
}

.contact-form textarea:focus,
.contact-form input[type="text"]:focus {
  outline: 4px solid var(--tertiary-color);
}

/* md para cima */
@media (min-width: 768px) {
  .section-text h2 span {
    display: inline;
  }

  .section-text h2 span:not(:last-child)::after {
    content: "\00a0/\00a0"; /* &nbsp;/&nbsp; */
  }

  .section-text p {
    padding: 0px 120px;
  }

  .contact-form {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(3, max-content);
    grid-template-areas:
      ". . ."
      "textarea textarea textarea"
      "submit-btn submit-btn submit-btn";
    gap: 15px;
    background: rgba(255, 255, 255, 0.5);
    padding: 15px;
    border-radius: 5px;
  }


  .contact-form textarea {
    /* grid-column-start: 1;
    grid-column-end: 4; */
    grid-area: textarea;
  }

  .contact-form input[type="submit"] {
    grid-area: submit-btn;
  }
}
</style>
