<template>
  <div class="landing-page">
    <Header />
    <ScrollToTopButton />
    <HeroSection />
    <AboutUsSection id="15dfb042-about-us-section" />
    <SolutionsSection id="15dfb042-solution-section" />
    <OurStepsSection id="15dfb042-our-steps-section" />
    <HireUsSection id="15dfb042-hire-us-section" />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import ScrollToTopButton from "@/components/ScrollToTopButton.vue";
import HeroSection from "./components/HeroSection.vue";
import AboutUsSection from "./components/AboutUsSection.vue";
import SolutionsSection from "./components/SolutionsSection.vue";
import OurStepsSection from "./components/OurStepsSection.vue";
import HireUsSection from "./components/HireUsSection.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'LandingPage',

  components: {
    Header,
    ScrollToTopButton,
    HeroSection,
    AboutUsSection,
    SolutionsSection,
    OurStepsSection,
    HireUsSection,
    Footer,
  },

  mounted() {
    document.documentElement.classList.add("landing-page-html-background");
  },

  beforeDestroy() {
    document.documentElement.classList.remove("landing-page-html-background");
  }
}
</script>

<style scoped>
html {
  background: black;
}

.landing-page {
  font-family: 'Poppins', sans-serif;
}
</style>
