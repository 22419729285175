<template>
  <section class="pt-header-size section-default-min-height-with-header">
    <div class="section-wrapper">
      <div class="text-container">
        <hr class="short-hr-line short-hr-line--center bg-primary">
        <h2 class="title-h2 text-secondary">Nossos Passos</h2>
        <h1 class="title-h1 text-primary">Conheça o ciclo de um projeto da NEUCO</h1>
      </div>
      <div class="step-icon-container-wrapper">
        <StepIconContainer class="step-icon-container" />
      </div>
    </div>
  </section>
</template>

<script>
import StepIconContainer from "./StepIconContainer.vue";

export default {
  name: 'OurStepsSection',
  components: {
    StepIconContainer,
  }
}
</script>

<style scoped>
section {
  padding-bottom: 6.25rem;
  display: flex;
  flex-direction: column;
  background: white;
  /* https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height */
  /* height: 1px; */
}

.section-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 0px 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height */
  /* height: 100%; */
}

/* TEXT */
.text-container {
  text-align: center;
  margin-bottom: 30px;
}

.text-container h2 {
  margin-top: 15px;
}

/* STEP ICON CONTAINER */
.step-icon-container-wrapper {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
}

.step-icon-container {
  flex: 1;
}
</style>
